<template>
  <v-dialog width="unset" v-model="dialog">
    <v-card light width="500px" clas="justify-center" rounded raised>
      <v-toolbar flat>
        <v-card-title class="px-2">Card Information</v-card-title>
        <v-spacer />
        <v-btn icon @click="cleanIput()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form v-model="valid" ref="form" lazy-validation>
          <template v-if="showBilling">
            <v-row>
              <v-col>
                <v-text-field
                  label="First Name"
                  v-model="firstname"
                  :rules="inputRule"
                  validate-on-blur
                />
              </v-col>
              <v-col>
                <v-text-field
                  label="Last Name"
                  v-model="lastname"
                  :rules="inputRule"
                  validate-on-blur
                />
              </v-col>
            </v-row>
            <v-text-field
              label="Address"
              v-model="address"
              :rules="inputRule"
              valdiate-on-blur
            />
            <v-text-field
              label="Zip Code"
              v-model="zipcode"
              :rules="[...inputRule, ...onlyNumsRule]"
              validate-on-blur
            />
          </template>
          <v-row>
            <v-col>
              <v-text-field
                label="Card Number"
                v-model="account"
                :rules="[...inputRule, ...accountRule, ...onlyNumsRule]"
                validate-on-blur
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Card CVV"
                v-model="cvv"
                :rules="[...inputRule, ...cvvRule, ...onlyNumsRule]"
                validate-on-blur
              />
            </v-col>
          </v-row>
          <v-text-field
            v-if="showAmount"
            label="Amount"
            v-model="amount"
            :rules="[...inputRule, ...decimalRule]"
            validate-on-blur
          />
          <CardExpiration v-model="expiry" :rules="inputRule" />
          <v-text-field
            v-if="!showBilling"
            label="Zip Code"
            v-model="zipcode"
            :rules="[...inputRule, ...onlyNumsRule]"
            validate-on-blur
          />
          <v-row class="mt-2">
            <v-col md="6">
              <v-btn
                class="mx-0"
                block
                color="#1F6070"
                dark
                @click="cleanIput()"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-col md="6">
              <v-btn
                class="mx-0"
                block
                color="#1F6070"
                dark
                @click="submit"
                :loading="submitting"
              >
                Done
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CardExpiration from './CardExpiration.vue';

export default {
  components: { CardExpiration },
  props: {
    // dialog: {
    //   type: Boolean
    // },
    submitting: {
      type: Boolean
    },
    showBilling: {
      type: Boolean
    },
    showAmount: {
      type: Boolean
    },
    value: {
      type: Object,
      default: function () {
        return {
          profileid: 0,
          account: '',
          cvv: '',
          expiry: '',
          zipcode: ''
        };
      }
    }
  },
  computed: {
    account: {
      get() {
        return this.value.account;
      },
      set(val) {
        this.onChanged({ account: val });
      }
    },
    amount: {
      get() {
        return this.value.amount;
      },
      set(val) {
        this.onChanged({ amount: val });
      }
    },
    cvv: {
      get() {
        return this.value.cvv;
      },
      set(val) {
        this.onChanged({ cvv: val });
      }
    },
    expiry: {
      get() {
        return this.value.expiry;
      },
      set(val) {
        this.onChanged({ expiry: val });
      }
    },
    zipcode: {
      get() {
        return this.value.zipcode;
      },
      set(val) {
        this.onChanged({ zipcode: val });
      }
    },
    firstname: {
      get() {
        return this.value.firstname;
      },
      set(val) {
        this.onChanged({ firstname: val });
      }
    },
    lastname: {
      get() {
        return this.value.lastname;
      },
      set(val) {
        this.onChanged({ lastname: val });
      }
    },
    address: {
      get() {
        return this.value.address;
      },
      set(val) {
        this.onChanged({ address: val });
      }
    }
  },
  data() {
    return {
      dialog: false,
      valid: false,
      inputRule: [(v) => !!v || 'Input Required'],
      accountRule: [
        (v) =>
          (v && (v.length == 15 || v.length == 16)) ||
          'Card number should be 15-16 characters long'
      ],
      decimalRule: [
        (v) =>
          !isNaN(Number.parseFloat(v)) || 'Input must be a valid decimal value'
      ],
      cvvRule: [
        (v) =>
          (v && (v.length == 3 || v.length == 4)) || 'Must be 3 or 4 characters'
      ],
      onlyNumsRule: [(v) => (v && /^\d+$/.test(v)) || 'Only numbers allowed']
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit('submit');
      }
    },
    onChanged(val) {
      this.$emit('input', { ...this.value, ...val });
    },
    show() {
      this.$emit('input', {
        profileid: 0,
        account: '',
        cvv: '',
        expiry: '',
        zipcode: '',
        amount: '',
        address: '',
        lastname: '',
        firstname: ''
      });

      this.dialog = true;
    },
    cleanIput() {
      this.$emit('input', {
        profileid: 0,
        account: '',
        cvv: '',
        expiry: '',
        zipcode: '',
        amount: '',
        address: '',
        lastname: '',
        firstname: ''
      });
      this.dialog = false;
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.v-btn {
  margin: 5px;
}
</style>
