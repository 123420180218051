<template>
  <v-container>
    <v-card :loading="loading">
      <v-card-title class="justify-center">
        Patient Balance: $ {{ total }}
      </v-card-title>
      <v-card-actions>
        <v-btn dark color="#1F6070" rounded block large @click="openModal()">
          Make a Payment
          <v-icon right>mdi-account-cash</v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-actions>
        <v-btn
          dark
          color="#1F6070"
          rounded
          block
          large
          v-if="showFinancialLedger"
          @click="dialogItemizedLedger = !dialogItemizedLedger"
        >
          Request Itemized Ledger
        </v-btn>
      </v-card-actions>
      <CardInfo
        ref="cardInfo"
        v-model="card"
        :dialog="dialog"
        :submitting="submitting"
        @close="dialog = !dialog"
        @submit="pay"
        showBilling
        showAmount
      />
      <DialogItemizedLedger
        :dialog="dialogItemizedLedger"
        @close="dialogItemizedLedger = !dialogItemizedLedger"
        @submit="sendMessage"
      />
    </v-card>
    <br />
    <v-card :loading="loading" v-if="showCryoBalanceCard">
      <v-card-title class="justify-center">
        Cryo Balance: $ {{ balance }}
      </v-card-title>
      <v-card-actions>
        <v-btn dark color="primary" rounded block large @click="toCryoLink">
          Manage & Pay Cryo Balance
        </v-btn>
      </v-card-actions>
      <CardInfo
        ref="cardInfo"
        v-model="card"
        :dialog="dialog"
        :submitting="submitting"
        @close="dialog = !dialog"
        @submit="pay"
        showBilling
        showAmount
      />
      <DialogItemizedLedger
        :dialog="dialogItemizedLedger"
        @close="dialogItemizedLedger = !dialogItemizedLedger"
        @submit="sendMessage"
      />
    </v-card>
    <Notify
      v-if="notification.show"
      :width="containerWidth"
      :type="notification.type"
      no-title
      @close="notification.show = false"
      @consent="notification.show = false"
      :message="notification.message"
    />
  </v-container>
</template>

<script>
import CardInfo from '../components/CardInfo.vue';
import DialogItemizedLedger from '../components/DialogItemizedLedger.vue';
import { api, auth, messages, partner } from '../sharedPlugin';
import Notify from '@/components/global/Notify';
const Swal = require('sweetalert2');

export default {
  components: { CardInfo, DialogItemizedLedger, Notify },
  data() {
    return {
      loading: false,
      submitting: false,
      dialog: false,
      dialogItemizedLedger: false,
      //total will be pulled from all bills in the api
      finances: {
        balance: 0,
        Company_ID: null,
        Company: '',
        Credit: null,
        Message: '',
        total: '0.00'
      },
      card: {
        account: null,
        amount: '',
        cvv: null,
        expiry: '',
        zipcode: null,
        cardtype: null,
        firstname: '',
        lastname: '',
        address: ''
      },
      showFinancialLedger: false,
      financialLedgerGroup: null,
      createFinancialLedgerDocument: false,
      balance: 0,
      showCryoBalanceCard: false,
      notification: {
        show: false,
        message: null,
        type: 'error'
      }
    };
  },
  computed: {
    ...auth.mapComputed(['user']),
    ...partner.mapComputed(['getPartner']),
    total() {
      return this.finances.balance ? this.finances.balance.toFixed(2) : 0;
    },
    isEnabled() {
      return this.finances.Company_ID !== null;
    }
  },
  mounted() {
    if (this.user?.features?.cryo) {
      this.showCryoBalanceCard = true;
      this.getBalance();
    }

    this.loadBalance();

    this.showFinancialLedger =
      this.user?.clinicSettings?.show_financial_ledger || false;
    this.financialLedgerGroup =
      this.user?.clinicSettings?.financial_ledger_group || null;
    this.createFinancialLedgerDocument =
      this.user?.clinicSettings?.create_financial_ledger_document || false;
  },
  methods: {
    ...messages.mapMethods([
      'setMessageTo',
      'pullMessageGroupsAsync',
      'createConversationAsync'
    ]),
    openModal() {
      this.$refs.cardInfo.show();
    },
    loadBalance() {
      this.loading = true;
      api.Finance.getBalance()
        .then((res) => (this.finances = res))
        .finally(() => (this.loading = false));
    },
    pay() {
      this.submitting = true;
      api.Finance.pay({
        ...this.finances,
        ...this.card,
        total: this.card.amount,
        client: this.user.clinicId,
        patientid: this.user.userId
      })
        .then((res) => {
          this.dialog = false;

          if (res && res.AppCode) {
            Swal.fire({
              title: 'Payment Successful!',
              text: 'Request sent successfully!',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'ok'
            }).then((result) => {
              this.$refs.cardInfo.cleanIput();
              this.loadBalance();
            });
          } else {
            Swal.fire({
              title: 'Payment failed!',
              text: res && res.Message ? res.Message : 'Payment failed!',
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'ok'
            }).then((result) => {
              this.$refs.cardInfo.cleanIput();
              this.loadBalance();
            });
          }
        })
        .catch((err) => {
          this.$store.commit('showSnackbar', {
            message: err,
            color: 'red'
          });
        })
        .finally(() => (this.submitting = false));
    },
    async sendMessage() {
      try {
        this.dialogItemizedLedger = false;

        /*let messageTo = {
          subject: 'Itemized Ledger',
          message:
            'I would like to request an itemized ledger, please send it as soon as possible.',
          botShown: true,
          groupId: this.financialLedgerGroup
        };*/

        let username = this.user?.fullName || 'You';

        if (
          this.getPartner &&
          this.getPartner.ownerName &&
          this.getPartner.name
        ) {
          let firtNameOwner = this.getPartner.ownerName.split(' ');
          let firtNamePartner = this.getPartner.name.split(' ');
          username = firtNameOwner[0] + ' on behalf of ' + firtNamePartner[0];
        }

        if (this.user?.clinicSettings?.create_financial_ledger_document) {
          api.Documents.createFinancialLedgerDocument()
            .then((res) => console.log(res))
            .finally(() => {});
        }

        const data = {
          subject: 'Itemized Ledger',
          message:
            'I would like to request an itemized ledger, please send it as soon as possible.',
          botShown: true,
          groupId: this.financialLedgerGroup,
          patientId: this.user.userId,
          patientRequestId: null,
          attachmentName: null,
          attachmentFile: null,
          createdByName: username
        };
        await api.Conversations.addConversation(data)
          .then((res) => {
            this.notification.message = `Your request for an itemized ledger has been received.`;
            this.notification.type = 'success';
            this.notification.show = true;
          })
          .catch((err) => {
            console.log("ERROR: couldn't create conversation", err);

            this.notification.message = `Your request for an itemized ledger failed!`;
            this.notification.type = 'error';
            this.notification.show = true;
          })
          .finally(() => (this.isLoading = false));
      } catch (error) {
        console.log(error);
      }
    },
    formatPrice(value) {
      return value ? value.toFixed(2) : 0.0;
    },
    getBalance() {
      this.loading = true;
      api.Cryo.getCryoBalance()
        .then((res) => {
          if (res.length > 0) {
            let tempBalance = res[0]?.balance;
            this.balance = tempBalance ? tempBalance?.toFixed(2) : '0.00'; //this.formatPrice(tempBalance);
          }
        })
        .finally(() => (this.loading = false));
    },
    toCryoLink() {
      this.$router.push({ path: '/cryo' });
    }
  }
};
</script>
