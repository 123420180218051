<template>
  <v-container
    fluid
    class="notify-container"
    :style="width ? `width:${width}px` : ''"
  >
    <v-card v-if="type === 'error'" class="notification--error" raised>
      <v-card-title v-if="!noTitle" class="title justify-center">
        {{ title }}
      </v-card-title>
      <v-card-text class="text">{{ message }}</v-card-text>
      <v-card-actions class="justify-center">
        <Btn width="120px" theme="active" @click="close">OK</Btn>
      </v-card-actions>
    </v-card>

    <v-card v-if="type === 'success'" class="notification" raised>
      <v-card-title v-if="!noTitle" class="title justify-center">
        {{ title }}
      </v-card-title>
      <v-card-text class="text">{{ message }}</v-card-text>
      <v-card-actions class="justify-center">
        <Btn width="120px" theme="active" @click="consent">OK</Btn>
      </v-card-actions>
    </v-card>

    <v-card v-if="type === 'confirm'" class="notification" raised>
      <v-card-title v-if="!noTitle" class="title justify-center">
        {{ title }}
      </v-card-title>
      <v-card-text class="text">{{ message }}</v-card-text>
      <v-card-actions class="justify-center">
        <v-btn @click="unlinkPartner" class="bottom-btn-cancel" small>
          CANCEL
        </v-btn>
        <v-btn @click="linkPartner" class="button-confirm" small>CONFIRM</v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-if="type === 'confirmation_payments'" class="notification" raised>
      <v-card-title v-if="!noTitle" class="title justify-center">
        {{ title }}
      </v-card-title>
      <v-card-text class="text">
        <span>
          I understand the request will be processed in 3-5
          <b class="labelBold">business</b>
          {{ messageRequest }}
        </span>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn @click="cancel" class="button-cancel-payment" small>NO</v-btn>
        <v-btn @click="consent" class="button-ok-payment" small>YES</v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-if="type === 'unlink'" class="notification" raised>
      <v-card-title class="title justify-center">
        {{ title }}
      </v-card-title>
      <v-card-text class="text">{{ message }}</v-card-text>
      <v-card-actions class="justify-center">
        <v-btn @click="unlinkPartner" class="bottom-btn-cancel" small>
          YES
        </v-btn>
        <v-btn @click="linkPartner" class="button-confirm" small>NO</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    noTitle: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    messageRequest: `days and will be available in the "Documents" section.`
  }),
  methods: {
    consent() {
      this.$emit('consent');
    },
    close() {
      this.$emit('close');
    },
    unlinkPartner() {
      this.$emit('unlinkPartner');
    },
    linkPartner() {
      this.$emit('linkPartner');
    },
    cancel() {
      this.$emit('cancel');
    }
  }
};
</script>

<style lang="scss">
.notify-container {
  position: absolute;
  bottom: 0;
  padding: 0 !important;

  .title {
    margin: 5px 0 5px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
  }

  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.0041em;
    text-align: center;
    color: #ffffff !important;
  }

  .notification {
    background-color: #1f6070;
    z-index: 10;
    width: 100%;
    padding: 0 16px 0 16px;

    &--error {
      background-color: #cb2a2a;
      z-index: 10;
      width: 100%;
      padding: 0 16px 0 16px;
    }
  }

  .button-ok {
    width: 30%;
    background-color: #fff;
    color: black;
    height: 38;
    font-size: 12;
    font-weight: 600;
    border-radius: 20;
    font-family: 'SF Pro Display';
    padding: 0;
    margin-top: 10;
    margin-bottom: 0;
    margin-left: 16;
    margin-right: 16;
  }

  .bottom-btn-cancel {
    width: 30%;
    border-radius: 50;
    background-color: transparent;
    border-color: white;
    border-width: 1;
    color: white;
    font-size: 12;
    font-weight: 600;
    font-family: 'SF Pro Display';
  }

  .button-confirm {
    width: 30%;
    border-radius: 50;
    background-color: white;
    color: black;
    font-size: 15;
    font-size: 12;
    font-weight: 600;
    font-family: 'SF Pro Display';
  }

  .button-cancel-payment {
    width: 120;
    height: 38;
    font-weight: 600;
    background-color: #1f6070;
    border-width: 1;
    border-color: #ffffff;
    border-style: solid;
    border-radius: 100;
    text-transform: uppercase;
    color: #ffffff;
  }

  .button-ok-payment {
    width: 120;
    height: 38;
    background: #ffffff;
    border-radius: 100;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #000000;
  }

  .labelBold {
    color: #fff;
    font-weight: bold;
  }
}
</style>
