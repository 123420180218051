<template>
  <v-dialog width="unset" v-model="show">
    <v-card
      light
      width="500px"
      height="180px"
      clas="justify-center"
      rounded
      raised
    >
      <v-toolbar flat>
        <v-card-title>Request Itemized Ledger</v-card-title>
      </v-toolbar>
      <v-card-text
        style="font-size: 1.3rem; font-weight: 300; text-align: center"
      >
        Do you want to request itemized ledger?
      </v-card-text>
      <v-row style="width: 95%">
        <v-col md="6">
          <v-btn
            block
            dark
            color="primary"
            style="margin-left: 20px; margin-right: 20px"
            @click="$emit('close')"
          >
            Cancel
          </v-btn>
        </v-col>
        <v-col md="6">
          <v-btn
            block
            dark
            color="primary"
            style="margin-right: 20px; margin-left: 20px"
            @click="submit"
          >
            Yes
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean
    }
  },
  computed: {
    show: {
      get() {
        return this.dialog;
      },
      set(dialog) {
        this.$emit('close');
      }
    }
  },
  data() {
    return {
      valid: false
    };
  },
  methods: {
    submit() {
      this.$emit('submit');
    },
    onChanged(val) {}
  }
};
</script>

<style scoped>
.v-btn {
  margin: 5px;
}
</style>
